import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Navbar, Nav } from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";
import Toolbar from "@mui/material/Toolbar";
import { LowerNav } from "../navigation/NavBar";
import AOS from "aos";
import "aos/dist/aos.css";

const siteId = process.env.REACT_APP_SITE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantURL = process.env.REACT_APP_SITE;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const logoHeight = process.env.REACT_APP_LOGO_HEIGHT;
const bgVariant = process.env.REACT_APP_NAVBAR_BGVARIANT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const enableEloyalty = process.env.REACT_APP_ENABLE_ELOYALTY;
const pdfViewer = parseInt(process.env.REACT_APP_PDFVIEWER_NOMENU);

export const MenuNavigation = () => {
  useEffect(() => {
    AOS.init({
      duration: 200,
    });
  }, []);

  const history = useHistory();
  const [cookieSIC, setCookieSIC, removeCookieSIC] = useCookies([
    "sic_name",
    "sic_user_id",
  ]);

  const query = enableKiosk === "0" ? "(max-width: 600px)" : "(max-width: 0px)";
  const isMobile = useMediaQuery(query);

  return (
    <>
      <Navbar
        bg={bgVariant}
        variant={bgVariant}
        expand="lg"
        className={
          enableKiosk === "0"
            ? "sticky-sm-top navBarKeto"
            : "HeaderImgeKiosk sticky-sm-top"
        }
        zIndex={999}
        data-aos-easing="ease-in-out"
        data-aos-delay="0"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href={restaurantURL}>
          <img
            width={logoWidth}
            height={logoHeight}
            title={`${restaurantName} logo`}
            className="App-logo"
            src={siteMedSrc + "logo" + ".webp"}
            alt={`${restaurantName} | ${restaurantURL}`}
            loading="lazy"
          />
        </Navbar.Brand>

        {enableKiosk === "0" ? (
          <Navbar.Collapse id="basic-navbar-nav">
            {siteId != 245 && (
              <>
                <Nav className="mr-auto">
                  <Nav.Link
                    onClick={() => {
                      history.push(restaurantURL);
                    }}
                  >
                    Home
                  </Nav.Link>
                  {
                    <Nav.Link
                      onClick={() => {
                        history.push(`/about`);
                      }}
                    >
                      About Us
                    </Nav.Link>
                  }
                  {/* <Nav.Link
                    href="#"
                    onClick={() => {
                      history.push("/team");
                    }}
                  >
                    Team
                  </Nav.Link> */}
                  <Nav.Link
                    onClick={() => {
                      history.push(`/contact`);
                    }}
                  >
                    Contact
                  </Nav.Link>
                  {enableEloyalty === "1" ? (
                    <Nav.Link
                      onClick={() => {
                        history.push(
                          cookieSIC.sic_user_id > 0 ? `/rewards` : `/register`
                        );
                      }}
                    >
                      {cookieSIC.sic_user_id > 0
                        ? "eLoyalty"
                        : "Join AJ's Club"}
                    </Nav.Link>
                  ) : null}
                  <Nav.Link
                    onClick={() => {
                      history.push(`/photo-gallery`);
                    }}
                  >
                    Photo Gallery
                  </Nav.Link>
                  <Nav.Link
                    className="red"
                    style={{
                      color: "white",
                      backgroundColor: buttonColor,
                    }}
                    onClick={() => {
                      history.push(`/menu`);
                    }}
                  >
                    {pdfViewer === 1 ? "Our Menu" : "Order Now"}
                  </Nav.Link>
                </Nav>
                <span className="white topbtn">
                  {!isMobile
                    ? cookieSIC.sic_firstName
                      ? parseHtml(
                          "Welcome back <b>" + cookieSIC.sic_firstName + "!</b>"
                        )
                      : null
                    : null}
                </span>
              </>
            )}
          </Navbar.Collapse>
        ) : (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                mx: "auto",
                marginTop: 0,
                // paddingRight: "70px"
              }}
            >
              <h3 style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "1.75rem" }}>
                {tagline} <b className="black"></b>
              </h3>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                marginTop: 0,
              }}
            >
              <LowerNav />
            </Box>
          </Toolbar>
        )}
      </Navbar>
    </>
  );
};
