import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import { MenuNavigation } from "../components/MenuNavigation";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountForm from "./components/AccountForm";
import MobileForm from "./components/MobileForm";
import PersonalDetails from "./components/PersonalDetails";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const restaurantURL = process.env.REACT_APP_SITE;

export const Register = () => {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            title="ServingIntel"
            alt="Point of Sale: Senior Living POS System"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  const steps = ["Account", "Mobile", "Personal Details"];
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cemail, setCEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [DOB, setDOB] = useState("");
  const [anniv, setAnniv] = useState("");

  const history = useHistory();

  useEffect(() => {
    const eloyaltyContentText = config.eloyaltycontent;

    seteloyaltyContent(eloyaltyContentText);
  }, []);

  const [eloyaltyContent, seteloyaltyContent] = useState("");

  const getFirstName = (val) => {
    setFirstName(val);
  };
  const getLastName = (val) => {
    setLastName(val);
  };
  const getEmail = (val) => {
    setEmail(val);
  };
  const getCEmail = (val) => {
    setCEmail(val);
  };
  const getMobile = (val) => {
    setMobile(val);
  };
  const getPassword = (val) => {
    setPassword(val);
  };
  const getCPassword = (val) => {
    setCPassword(val);
  };
  const getDOB = (val) => {
    setDOB(val);
  };
  const getAnniv = (val) => {
    setAnniv(val);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AccountForm
            getFirstName={getFirstName.bind(this)}
            firstName={firstName}
            getLastName={getLastName.bind(this)}
            lastName={lastName}
            getEmail={getEmail.bind(this)}
            email={email}
            getCEmail={getCEmail.bind(this)}
            cemail={cemail}
          />
        );
      case 1:
        return (
          <MobileForm
            getMobile={getMobile.bind(this)}
            mobile={mobile}
            getPassword={getPassword.bind(this)}
            password={password}
            getCPassword={getCPassword.bind(this)}
            cpassword={cpassword}
          />
        );
      case 2:
        return (
          <PersonalDetails
            getDOB={getDOB.bind(this)}
            DOB={DOB}
            getAnniv={getAnniv.bind(this)}
            anniv={anniv}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const theme = createTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep == 0 && email && cemail && email != cemail) {
      Swal.fire({
        title: "Email Confirmation",
        text: "Email address did not match.",
        icon: "info",
        confirmButtonText: "OK",
      });
    } else if (activeStep == 1 && password != cpassword) {
      Swal.fire({
        title: "Password Confirmation",
        text: "Password did not match.",
        icon: "info",
        confirmButtonText: "OK",
      });
    } else if (!firstName || !lastName || !email || !cemail) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
    } else if (activeStep == 1 && (!password || !cpassword || !mobile)) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const submit = () => {
    const url =
      apiBaseURL +
      "/customer/register-eloyalty/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId;

    axios
      .post(url, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: mobile,
        password: password,
        DOB: DOB,
        anniv: anniv,
      })
      .then((response) => {
        if (response.data > 0) {
          handleNext();
        } else {
          if (response.data === -1) {
            // Email is already registered and fully active
            Swal.fire({
              title: "Registration Failed!",
              text: "This email is already registered. Please use another email.",
              icon: "info",
              confirmButtonText: "OK",
            });
          } else if (response.data === -2) {
            // Email is registered but not activated - prompt reactivation
            Swal.fire({
              title: "Account Reactivation",
              text: "This email is registered but not yet activated. Would you like us to resend the activation link?",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Resend Activation Link",
            }).then((result) => {
              if (result.isConfirmed) {
                // Resend activation link

                axios
                  .post(
                    `${apiBaseURL}/customer/register-eloyalty/${serverId}/${siteId}/${storeId}`,
                    {
                      email: email,
                      firstName: firstName,
                      lastName: lastName,
                      password: password,
                      mobile: mobile,
                      reactivate: true, // Set reactivation flag
                    }
                  )
                  .then((res) => {
                    if (res.data === 1) {
                      Swal.fire({
                        title: "Activation Link Sent",
                        text: "A new activation link has been sent to your email.",
                        icon: "success",
                        confirmButtonText: "OK",
                      });
                    } else {
                      Swal.fire({
                        title: "Error",
                        text: "Failed to resend activation link. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                      });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: "Error",
                      text: "Failed to resend activation link. Please try again later.",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                  });
              }
            });
          } else {
            Swal.fire({
              title: "Registration Failed!",
              text: "Fields marked with * are required",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        Swal.fire({
          title: "Registration Error",
          text: "An unexpected error occurred. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "4px 0" };

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <div
      className="customBg"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <MenuNavigation />
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${restaurantName}: ${eloyaltyContent.title}`}</title>
            <link rel="canonical" href={`${restaurantURL}register`} />
            <meta
              name="description"
              content={`${eloyaltyContent.description}`}
            />
            <meta name="keywords" content={`${eloyaltyContent.keywords}`} />
            <meta
              property="og:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="og:description"
              content={`${eloyaltyContent.description}`}
            />
            <meta
              property="twitter:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="twitter:description"
              content={`${eloyaltyContent.description}`}
            />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos="fade-top"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            {/* <a href={`${restaurantURL}`} target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".webp"}
                width="100%"
                height="100%"
                title={`${restaurantName} | ${eloyaltyContent.title}`}
                alt={`${restaurantName} | ${eloyaltyContent.description}`}
                loading="lazy"
              />
            </a> */}

            <Typography
              className="white"
              variant="h3"
              style={{ marginBottom: "10px", fontWeight: "550" }}
            >
              Join our AJ's Club
            </Typography>
            <div className="col-lg-12" data-aos-delay="300" data-aos="fade-top">
              <div className="white">
                <Typography variant="h4">Benefits include:</Typography>
                <ul className="paddingSpecial">
                  <li>receiving $5 in AJ Bucks for every $50 in purchases.</li>
                  <li>$10 in AJ Bucks for activating your account,</li>
                  <li>and emails with member-only specials and events!</li>
                </ul>
              </div>
            </div>
            <Paper
              data-aos-delay="500"
              data-aos="fade-top"
              style={{
                boxShadow: "none",
                marginTop: "0",
                backgroundColor: "transparent",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              <Stepper
                className="activeStep"
                activeStep={activeStep}
                // sx={{ pt: 2, pb: 3 }}
                style={{
                  background: "beige",
                  padding: "1.25rem 0",
                  borderRadius: "5px",
                }}
              >
                {steps.map((label) => (
                  <Step
                    onKeyUp={(e) => {
                      console.log(e);
                    }}
                    key={label}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Paper
                      data-aos-delay="600"
                      data-aos="fade-top"
                      className="formBG"
                      style={{
                        boxShadow: "none",
                        marginTop: "0",
                        marginBottom: "1rem",
                        background: "beige",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "1.25rem 0",
                        }}
                        variant="outlined"
                      >
                        <Typography
                          variant="contained"
                          style={{ margin: "1.25rem 0" }}
                        >
                          {
                            "Your account has been created and an activation link has been sent to the email address you entered. Note that you must activate the account by selecting the activation link when you get the email before you can login."
                          }
                        </Typography>
                      </Box>
                    </Paper>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {activeStep !== 0 && (
                        <Grid container spacing={0}>
                          <Button
                            variant="contained"
                            onClick={handleBack}
                            fullWidth
                            sx={{
                              marginRight: "5px !important",
                              mt: 3,
                              fontWeight: "550",
                            }}
                          >
                            Previous
                          </Button>
                        </Grid>
                      )}
                      <Grid container spacing={0}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => {
                            if (activeStep === steps.length - 1) {
                              submit();
                            } else {
                              handleNext();
                            }
                          }}
                          sx={{ mt: 3, ml: 1, fontWeight: "550" }}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </Grid>
                    </Box>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push("/login");
                        }}
                        size="large"
                        variant="contained"
                        style={{
                          fontWeight: "550",
                          backgroundColor: buttonColor,
                          borderColor: "none",
                          margin: ".5rem 0",
                        }}
                      >
                        Login if you already have account
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>

            <Grid
              container
              spacing={1}
              data-aos-delay="700"
              data-aos="fade-top"
            >
              {activeStep != 3 ? (
                <React.Fragment>
                  {/* <Grid item xs={6}>
                    <Button
                      fullWidth
                      // onClick={formik.handleSubmit}
                      size="large"
                      variant="contained"
                      style={{ fontWeight: "550" }}
                    >
                      Register
                    </Button>
                  </Grid> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={() => history.push("/login")}
                      size="large"
                      variant="contained"
                      style={{
                        fontWeight: "550",
                        borderColor: "none",
                        marginBottom: "1rem",
                      }}
                    >
                      Login your verified account
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Box>
          <Copyright />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(Register);
